import { extendTheme } from "@chakra-ui/core"

const Accordion = {
  parts: ["container", "button"],
  baseStyle: {
    container: {
      border: "none",
      borderRadius: "md",
      py: "2",
      mb: "2",
      bg: "gray.100",
    },
  },
}

const theme = extendTheme({
  fonts: {
    body: "system-ui, sans-serif",
    heading: "Prompt, Georgia, serif",
    mono: "Menlo, monospace",
  },
  components: {
    Accordion,
  },
  textStyles: {
    h1: {
      fontSize: "4xl",
      fontWeight: "medium",
      lineHeight: "110%",
      letterSpacing: "-2%",
    },
    h2: {
      fontSize: "3xl",
      fontWeight: "medium",
      lineHeight: "110%",
      letterSpacing: "-1%",
    },
    h3: {
      fontSize: "2xl",
      fontWeight: "normal",
    },
    h4: {
      fontSize: "xl",
      fontWeight: "normal",
    },
    "body-1": {
      fontSize: "lg",
      letterSpacing: "1%",
      color: "gray.700",
    },
  },
})

export default theme
